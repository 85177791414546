:root {
  --accent: #866D38;
  --secondary: #D9CCAB;
  --primary: #B9A36D;
  --light: #E6E9DE;
  --variant: #B6BDA3;
}

.guide {
  position: absolute;
  left: 3em;
  top: -1em;
  border-radius: .8em;
  box-shadow: 0 8px 10px 0 rgba(0,0,0,0.28),
              0 2px 20px 0 rgba(0,0,0,0.24),
              0 4px 8px -1px rgba(0,0,0,0.6);
  padding: .8em 1.8em;
  background-color: var(--light);
  opacity: 0;
  visibility: hidden;
  transition: opacity .4s;
}
.show {
  opacity: 1;
  visibility: visible;
}
.guide > p {
  text-indent: 2em;
}
.close {
  position: sticky !important;
  top: -.5em;
  float: right;
  line-height: 18px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.brief {
  display: flex;
  justify-content: space-around;
}
.brief > pre {
  flex-basis: 0;
  flex-grow: 1;
  margin: 0 1em;
}

pre, .explain {
  font-family: 'Noto Sans SC', sans-serif;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.07),
              0 1px 10px 0 rgba(0,0,0,0.06),
              0 2px 4px -1px rgba(0,0,0,0.15);
  border-radius: .8em;
  padding: 1.2em;
  background-color: #EEE;
  white-space: pre;
}

.dot{
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.20) 0px 1px 2px;
  border-radius: 100%;
  padding: .6em;
  background-color: var(--variant);
  color: white;
  user-select: none;
  text-align: center;
  transition: opacity .3s;
}
.tag {
  top: -.2em;
  left: .2em;
  line-height: 10px;
  height: 10px;
  width: 10px;
  cursor: help;
}
.explain {
  position: absolute;
  top: -.2em;
  left: 2.5em;
  width: 11em;
  background-color: white;
  opacity: 0;
  white-space: normal;
  transition: opacity .4s;
  user-select: none;
  cursor: default;
}

.tag:hover + .explain {
  opacity: 1;
}

.guide-button {
  top: 3em;
  right: 2em;
  margin: 0;
  line-height: 18px;
  height: 18px;
  width: 18px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}
