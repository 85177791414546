@import url(https://fonts.googleapis.com/css?family=Noto+Sans+SC&display=swap);
:root {
  --accent: #866D38;
  --secondary: #D9CCAB;
  --primary: #B9A36D;
  --light: #E6E9DE;
  --variant: #B6BDA3;
}

html {
  font-family: 'Noto Sans SC', sans-serif;
  font-size: calc(16px + 9 * (100vmax - 1024px) / (2400 - 1024));
  background-color: #F3F4F0;
  overflow: hidden;
}
form {
  height: 100%;
  width: 100%;
  padding: 0 0;
  display: flex;
}
form > div {
  height: 88vh;
  width: 46%;
  margin: 7vh auto 5vh auto;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: .5em 1em;
}
form > div:first-child {
  width: 63%;
}

section {
  position: relative;
  padding-top: 50.625%; /* .50625/.9 = 9/16 */
  width: 90%;
  border-radius: .3em;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.28),
              0 1px 10px 0 rgba(0,0,0,0.24),
              0 2px 4px -1px rgba(0,0,0,0.6);
  margin: 1em auto 4em auto;
  font-size: 0.9em;
  background-color: #E6E9DE;
  background-color: var(--light);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-animation: config_fadein__DXwP3 .3s forwards ease-in;
          animation: config_fadein__DXwP3 .3s forwards ease-in;
}
input[type='file'], input[type='color'] {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: -1;
}
input[type='file']:not(:last-child) {
  left: 0;
  width: 50%;
}
input ~ input[type='color'] {
  right: 0;
  width: 50%;
}
section > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.1rem;
  white-space: nowrap;
  z-index: -1;
}
.config_sc-dark__1FPux > span {
  color: white;
}
.config_sc-text-shadow__3OkMl > span {
  text-shadow: #FFF 1px 0 10px, #FFF 1px 0 25px;
}
.config_sc-text-shadow__3OkMl.config_sc-dark__1FPux > span {
  text-shadow: #000 1px 0 10px, #000 1px 0 25px;
}

/* TODO: sc-cont-backdrop */

section > span p {
  font-size: 1.6rem;
  line-height: 1.6em;
  text-align: center;
  cursor: pointer;
  /* extra styling */
  margin: 0 auto;
  /* backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); TODO: sc */
}
section > span b {
  color: #866D38;
  color: var(--accent);
}

.config_pallet__2Z3Hz {
  position: absolute;
  top: 10%;
  right: -1em;
  border-radius: .3em;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14),
              0 1px 10px 0 rgba(0,0,0,0.12),
              0 2px 4px -1px rgba(0,0,0,0.3);
  padding: 0.3em 0.3em 0 0.3em;
  background: rgba(255,255,255,.65);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  opacity: 0;
  transition: opacity .4s;
}
.config_stay__34eaO, .config_blink__14V2S:hover, section:hover > .config_blink__14V2S {
  opacity: 1;
}
.config_candidate__ZtvyE {
  cursor: pointer;
  border-radius: .3em;
  padding: .3em;
  margin: .5em .5em;
  color: rgb(0,0,0);
  background: #B9A36D;
  background: var(--primary);
}
.config_owned__14rvi {
  color: rgba(0,0,0, 0.6);
  background: #D9CCAB !important;
  background: var(--secondary) !important;
}

.config_labelbox__1jkoS {
  position: absolute;
  top: 0;
  left: -1em;
}
.config_label__3Gsj6 {
  position: relative;
  margin: .5em 0;
  border-radius: .3em;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14),
              0 1px 10px 0 rgba(0,0,0,0.12),
              0 2px 4px -1px rgba(0,0,0,0.3);
  width: 5.5em;
  padding: 0.3em 0.5em;
  background: #B9A36D;
  background: var(--primary);
  -webkit-animation: config_fadein__DXwP3 .2s forwards ease-in;
          animation: config_fadein__DXwP3 .2s forwards ease-in;
}
.config_label__3Gsj6:before {
  border-left: 1.154em solid #B9A36D;
  border-left: 1.154em solid var(--primary);
  border-top: 1em solid transparent;
  border-bottom: 1em solid transparent;
  content: "";
  height: 0;
  right: -1.0em;
  position: absolute;
  top: 0;
  width: 0;
  -webkit-filter: drop-shadow(3px 4px 2px rgba(0,0,0,0.28));
          filter: drop-shadow(3px 4px 2px rgba(0,0,0,0.28));
}
.config_label__3Gsj6 > span {
  cursor: default;
}
.config_owned__14rvi.config_label__3Gsj6:before {
  border-left-color: #D9CCAB;
  border-left-color: var(--secondary);
}

.config_adv__37ePN {
  right: 2.3em;
  display: none; /* FIXME */
}
.config_del__1UMrw {
  right: -.7em;
}
.config_wid__pYQgn {
  width: 15px;
  border-radius: 100%;
  opacity: 0;
  position: absolute;
  top: -1em;
  box-shadow: rgba(0, 0, 0, 0.20) 0px 1px 2px;
  color: #866D38;
  color: var(--accent);
}
.config_del__1UMrw:hover, section:hover > .config_del__1UMrw, .config_label__3Gsj6:hover > .config_del__1UMrw {
  opacity: 1;
}
.config_ins__YJ_bJ {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 3em;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 1px;
  color: #866D38;
  color: var(--accent);
}
.config_dot__ih98Z {
  height: 15px;
  line-height: 15px;
  background-color: #B6BDA3;
  background-color: var(--variant);
  padding: .6em;
  cursor: pointer;
  transition: opacity .3s;
}

.config_choices__sVpO0 {
  cursor: pointer;
}
.config_choices__sVpO0 > span {
  display: inline-block;
  line-height: 1em;
  padding: .3em;
}
.config_chosen__2AhKy {
  background-color: #D9CCAB;
  background-color: var(--secondary);
}
.config_formtoggle__1A6Oq {
  position: absolute;
  top: 2em;
}

textarea {
  width: 95%;
  height: 80vh;
  margin: auto;
  border: none;
  border-radius: .8em;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14),
              0 1px 10px 0 rgba(0,0,0,0.12),
              0 2px 4px -1px rgba(0,0,0,0.3);
  padding: 1.2em;
  resize: none;
  box-sizing: border-box;
  font-size: 1em;
}
textarea:focus, button:focus {
  outline: none;
}
button {
  margin-top: .5em;
  border: none;
  border-radius: 1.2em;
  padding: .6em;
  color: #866D38;
  color: var(--accent);
  background-color: #B6BDA3;
  background-color: var(--variant);
  cursor: pointer;
  font-size: 1em;
  overflow: hidden;
}

.config_pending__22FCn {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: rgba(0,0,0,0.4);
  color: #E6E9DE;
  color: var(--light);
  font-size: 1.2em;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  -webkit-animation: config_fadein__DXwP3 .3s forwards ease-in;
          animation: config_fadein__DXwP3 .3s forwards ease-in;
}
.config_pending__22FCn > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
}
.config_pending__22FCn > div > * {
  margin: 1em auto;
  text-align: center;
}
progress {
  background-color: #777;
  border-radius: 3px;
  border: none;
  height: 8px;
}
progress::-webkit-progress-bar {
  background-color: #777;
  border-radius: 3px;
}
progress::-webkit-progress-value {
  background-color: #B6BDA3;
  background-color: var(--variant);
  border-radius: 3px;
  -webkit-transition: width 2s;
  transition: width 2s;
}
progress::-moz-progress-bar {
  background-color: #B6BDA3;
  background-color: var(--variant);
  border-radius: 3px;
  -moz-transition: width 2s;
  transition: width 2s;
}
a, a:visited {
  color: #D9CCAB;
  color: var(--secondary);
  background-color: #866D38;
  background-color: var(--accent);
}

@-webkit-keyframes config_fadein__DXwP3 {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes config_fadein__DXwP3 {
  from { opacity: 0; }
  to   { opacity: 1; }
}

:root {
  --accent: #866D38;
  --secondary: #D9CCAB;
  --primary: #B9A36D;
  --light: #E6E9DE;
  --variant: #B6BDA3;
}

.cheatsheet_guide__2Gj8r {
  position: absolute;
  left: 3em;
  top: -1em;
  border-radius: .8em;
  box-shadow: 0 8px 10px 0 rgba(0,0,0,0.28),
              0 2px 20px 0 rgba(0,0,0,0.24),
              0 4px 8px -1px rgba(0,0,0,0.6);
  padding: .8em 1.8em;
  background-color: #E6E9DE;
  background-color: var(--light);
  opacity: 0;
  visibility: hidden;
  transition: opacity .4s;
}
.cheatsheet_show__-Msj- {
  opacity: 1;
  visibility: visible;
}
.cheatsheet_guide__2Gj8r > p {
  text-indent: 2em;
}
.cheatsheet_close__1WsDT {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: -.5em;
  float: right;
  line-height: 18px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.cheatsheet_brief__1QzOU {
  display: flex;
  justify-content: space-around;
}
.cheatsheet_brief__1QzOU > pre {
  flex-basis: 0;
  flex-grow: 1;
  margin: 0 1em;
}

pre, .cheatsheet_explain__3FRfO {
  font-family: 'Noto Sans SC', sans-serif;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.07),
              0 1px 10px 0 rgba(0,0,0,0.06),
              0 2px 4px -1px rgba(0,0,0,0.15);
  border-radius: .8em;
  padding: 1.2em;
  background-color: #EEE;
  white-space: pre;
}

.cheatsheet_dot__yFHx6{
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.20) 0px 1px 2px;
  border-radius: 100%;
  padding: .6em;
  background-color: #B6BDA3;
  background-color: var(--variant);
  color: white;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: center;
  transition: opacity .3s;
}
.cheatsheet_tag__1ZAzI {
  top: -.2em;
  left: .2em;
  line-height: 10px;
  height: 10px;
  width: 10px;
  cursor: help;
}
.cheatsheet_explain__3FRfO {
  position: absolute;
  top: -.2em;
  left: 2.5em;
  width: 11em;
  background-color: white;
  opacity: 0;
  white-space: normal;
  transition: opacity .4s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: default;
}

.cheatsheet_tag__1ZAzI:hover + .cheatsheet_explain__3FRfO {
  opacity: 1;
}

.cheatsheet_guide-button__2eTJk {
  top: 3em;
  right: 2em;
  margin: 0;
  line-height: 18px;
  height: 18px;
  width: 18px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}

