:root {
  --accent: #866D38;
  --secondary: #D9CCAB;
  --primary: #B9A36D;
  --light: #E6E9DE;
  --variant: #B6BDA3;
}
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+SC&display=swap');

html {
  font-family: 'Noto Sans SC', sans-serif;
  font-size: calc(16px + 9 * (100vmax - 1024px) / (2400 - 1024));
  background-color: #F3F4F0;
  overflow: hidden;
}
form {
  height: 100%;
  width: 100%;
  padding: 0 0;
  display: flex;
}
form > div {
  height: 88vh;
  width: 46%;
  margin: 7vh auto 5vh auto;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: .5em 1em;
}
form > div:first-child {
  width: 63%;
}

section {
  position: relative;
  padding-top: 50.625%; /* .50625/.9 = 9/16 */
  width: 90%;
  border-radius: .3em;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.28),
              0 1px 10px 0 rgba(0,0,0,0.24),
              0 2px 4px -1px rgba(0,0,0,0.6);
  margin: 1em auto 4em auto;
  font-size: 0.9em;
  background-color: var(--light);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: fadein .3s forwards ease-in;
}
input[type='file'], input[type='color'] {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: -1;
}
input[type='file']:not(:last-child) {
  left: 0;
  width: 50%;
}
input ~ input[type='color'] {
  right: 0;
  width: 50%;
}
section > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.1rem;
  white-space: nowrap;
  z-index: -1;
}
.sc-dark > span {
  color: white;
}
.sc-text-shadow > span {
  text-shadow: #FFF 1px 0 10px, #FFF 1px 0 25px;
}
.sc-text-shadow.sc-dark > span {
  text-shadow: #000 1px 0 10px, #000 1px 0 25px;
}

/* TODO: sc-cont-backdrop */

section > span p {
  font-size: 1.6rem;
  line-height: 1.6em;
  text-align: center;
  cursor: pointer;
  /* extra styling */
  margin: 0 auto;
  /* backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); TODO: sc */
}
section > span b {
  color: var(--accent);
}

.pallet {
  position: absolute;
  top: 10%;
  right: -1em;
  border-radius: .3em;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14),
              0 1px 10px 0 rgba(0,0,0,0.12),
              0 2px 4px -1px rgba(0,0,0,0.3);
  padding: 0.3em 0.3em 0 0.3em;
  background: rgba(255,255,255,.65);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  opacity: 0;
  transition: opacity .4s;
}
.stay, .blink:hover, section:hover > .blink {
  opacity: 1;
}
.candidate {
  cursor: pointer;
  border-radius: .3em;
  padding: .3em;
  margin: .5em .5em;
  color: rgb(0,0,0);
  background: var(--primary);
}
.owned {
  color: rgba(0,0,0, 0.6);
  background: var(--secondary) !important;
}

.labelbox {
  position: absolute;
  top: 0;
  left: -1em;
}
.label {
  position: relative;
  margin: .5em 0;
  border-radius: .3em;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14),
              0 1px 10px 0 rgba(0,0,0,0.12),
              0 2px 4px -1px rgba(0,0,0,0.3);
  width: 5.5em;
  padding: 0.3em 0.5em;
  background: var(--primary);
  animation: fadein .2s forwards ease-in;
}
.label:before {
  border-left: 1.154em solid var(--primary);
  border-top: 1em solid transparent;
  border-bottom: 1em solid transparent;
  content: "";
  height: 0;
  right: -1.0em;
  position: absolute;
  top: 0;
  width: 0;
  filter: drop-shadow(3px 4px 2px rgba(0,0,0,0.28));
}
.label > span {
  cursor: default;
}
.owned.label:before {
  border-left-color: var(--secondary);
}

.adv {
  right: 2.3em;
  display: none; /* FIXME */
}
.del {
  right: -.7em;
}
.wid {
  width: 15px;
  border-radius: 100%;
  opacity: 0;
  position: absolute;
  top: -1em;
  box-shadow: rgba(0, 0, 0, 0.20) 0px 1px 2px;
  color: var(--accent);
}
.del:hover, section:hover > .del, .label:hover > .del {
  opacity: 1;
}
.ins {
  width: fit-content;
  border-radius: 3em;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 1px;
  color: var(--accent);
}
.dot {
  height: 15px;
  line-height: 15px;
  background-color: var(--variant);
  padding: .6em;
  cursor: pointer;
  transition: opacity .3s;
}

.choices {
  cursor: pointer;
}
.choices > span {
  display: inline-block;
  line-height: 1em;
  padding: .3em;
}
.chosen {
  background-color: var(--secondary);
}
.formtoggle {
  position: absolute;
  top: 2em;
}

textarea {
  width: 95%;
  height: 80vh;
  margin: auto;
  border: none;
  border-radius: .8em;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14),
              0 1px 10px 0 rgba(0,0,0,0.12),
              0 2px 4px -1px rgba(0,0,0,0.3);
  padding: 1.2em;
  resize: none;
  box-sizing: border-box;
  font-size: 1em;
}
textarea:focus, button:focus {
  outline: none;
}
button {
  margin-top: .5em;
  border: none;
  border-radius: 1.2em;
  padding: .6em;
  color: var(--accent);
  background-color: var(--variant);
  cursor: pointer;
  font-size: 1em;
  overflow: hidden;
}

.pending {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: rgba(0,0,0,0.4);
  color: var(--light);
  font-size: 1.2em;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  animation: fadein .3s forwards ease-in;
}
.pending > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
}
.pending > div > * {
  margin: 1em auto;
  text-align: center;
}
progress {
  background-color: #777;
  border-radius: 3px;
  border: none;
  height: 8px;
}
progress::-webkit-progress-bar {
  background-color: #777;
  border-radius: 3px;
}
progress::-webkit-progress-value {
  background-color: var(--variant);
  border-radius: 3px;
  transition: width 2s;
}
progress::-moz-progress-bar {
  background-color: var(--variant);
  border-radius: 3px;
  transition: width 2s;
}
a, a:visited {
  color: var(--secondary);
  background-color: var(--accent);
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
